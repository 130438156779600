import React from 'react'
import Head from 'next/head'
import { createGlobalStyle } from 'styled-components'

import { theme } from '@damen/ui'
import { Script as StoryblokScript } from '@lib/storyblok'

export interface Props {
	description?: string
	storyblokUrl?: string
	title?: string
}

const GlobalStyle = createGlobalStyle`
    body {
        color: ${theme.colors.marineBlack};
    }
`

const BaseLayout: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	description = '',
	storyblokUrl,
	title
}) => {
	return (
		<>
			<Head>
				<meta name="description" content={description} />
				<title>{title}</title>
			</Head>
			<GlobalStyle />
			{children}
			<StoryblokScript storyblokUrl={storyblokUrl} />
		</>
	)
}

export default BaseLayout
