import React from 'react'
import styled from 'styled-components'

import BaseLayout, { Props as BaseProps } from '../Base'

const ErrorLayout: React.FC<React.PropsWithChildren<BaseProps>> = ({
	children,
	storyblokUrl,
	title
}) => (
	<BaseLayout storyblokUrl={storyblokUrl} title={title}>
		<Container>{children}</Container>
	</BaseLayout>
)

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: calc(100vh - 64px);
	background-color: ${({ theme }) => theme.colors.marineBlack};

	@media ${({ theme }) => theme.legacyMediaQueries.sm} {
		height: calc(100vh - 80px);
	}
`

export default ErrorLayout
