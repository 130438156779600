import React from 'react'

interface StoryblokScriptProps {
	storyblokUrl: string | undefined
}

const StoryblokScript: React.FC<StoryblokScriptProps> = ({ storyblokUrl }) =>
	storyblokUrl !== undefined ? (
		<script src={storyblokUrl} type="text/javascript" />
	) : (
		<></>
	)

export default StoryblokScript
