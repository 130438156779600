import React from 'react'
import { Button, Icon, Heading, Paragraph, theme, NextImage } from '@damen/ui'
import styled from 'styled-components'

interface Props {
	errorImage: ErrorImage
	buttonText: string
	message: string
	title: string
	onButtonClick: () => void
}

export type ErrorImage = {
	alt: string
	height: number
	width: number
	src: string
}

const Default: React.FC<Props> = ({
	errorImage: image,
	buttonText,
	message,
	title,
	onButtonClick
}) => (
	<Container>
		<NextImage
			alt={image.alt}
			height={image.height}
			src={image.src}
			width={image.width}
		/>
		<Heading.HeadingThree>{title}</Heading.HeadingThree>
		<Paragraph.Default>{message}</Paragraph.Default>
		<Button.Default
			icon={Icon.LeftChevron}
			text={buttonText}
			onClick={onButtonClick}
		/>
	</Container>
)

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 32px;
	color: ${theme.colors.white};
	text-align: center;

	h3 {
		margin: 56px 0 16px;
	}

	p {
		max-width: 480px;
		margin: 16px 0 32px;
	}

	button {
		align-self: center;
	}
`

export default Default
