import { GetStaticProps } from 'next'

import ErrorScreen from '../screens/errors'

export const getStaticProps: GetStaticProps = async (context) => {
	const { locale } = context

	const getProps = () => {
		switch (locale) {
			case 'en':
				return props.en
			case 'fr-fr':
				return props.fr
			case 'es-es':
				return props.es

			default:
				return props.en
		}
	}

	//! The source of truth for the translations is SB
	//* SB Root > Pages > 404
	const props = {
		en: {
			pageTitle: '404 - Page Not Found',
			buttonText: 'Back to home',
			message:
				'The page you’re looking for isn’t found. We suggest you go back to home.',
			title: 'There’s no place like home!'
		},
		fr: {
			pageTitle: '404 - Page non trouvée',
			buttonText: `Retour à la page d'accueil`,
			message: `La page que vous recherchez n'a pas été trouvée. Nous vous suggérons de rentrer chez vous.`,
			title: `Il n'y a pas d'endroit comme chez soi!`
		},
		es: {
			pageTitle: '404 - Página no encontrada',
			buttonText: 'Volver a la página de inicio',
			message:
				'La página que buscas no se encuentra. Te sugerimos que vuelvas a casa.',
			title: 'No hay lugar como el hogar!'
		}
	}

	const localeProps = getProps()

	return {
		props: {
			...localeProps,
			preview: true,
			errorImage: {
				alt: localeProps.pageTitle,
				height: 187,
				src: '/assets/images/404-Bottle-of-Rum.svg',
				width: 207
			}
		},
		revalidate: 1
	}
}

export default ErrorScreen
