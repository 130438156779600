import React from 'react'
import { useRouter } from 'next/router'

import Layout from '@components/ErrorLayout'
import PageHead from '@components/PageHead'

import { HOME } from '@src/constants/Routes'

import Presentational, { ErrorImage } from './presentational'

interface Props {
	buttonText: string
	message: string
	title: string
	pageTitle: string
	errorImage: ErrorImage
}

const Default: React.FC<Props> = ({
	buttonText,
	message,
	title,
	pageTitle,
	errorImage
}) => {
	const router = useRouter()

	return (
		<>
			<PageHead
				seoData={{ title: pageTitle }}
				headerColor="marineBlack"
			/>
			<Layout.Error title={pageTitle} description={message}>
				<Presentational
					errorImage={errorImage}
					buttonText={buttonText}
					message={message}
					title={title}
					onButtonClick={() => router.push(HOME)}
				/>
			</Layout.Error>
		</>
	)
}

export default Default
