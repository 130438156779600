import { useEffect } from 'react'
import { useApolloClient, DocumentNode } from '@apollo/client'

interface UsePreviewProps {
	data: any
	query: DocumentNode
}

const getStoryblokUrl = (preview: boolean) =>
	preview
		? `${process.env.STORYBLOK_BRIDGE_URL}${process.env.STORYBLOK_BRIDGE_KEY}`
		: ''

const usePreview = ({ data, query }: UsePreviewProps) => {
	const client = useApolloClient()

	useEffect(() => {
		if (window.storyblok) {
			const keys = Object.keys(data).map((key) => key)

			window.storyblok.on(['change', 'published'], () =>
				window.location.reload()
			)

			window.storyblok.on('input', (event: any) => {
				if (event.story.content._uid === data[keys[0]].content._uid) {
					const newValue = window.storyblok.addComments(
						event.story.content,
						event.story.id
					)

					const newData = { ...data }

					newData[keys[0]] = {
						...data[keys[0]],
						content: {
							...data[keys[0]].content,
							body: newValue.body
						}
					}

					client.writeQuery({
						query,
						data: newData
					})
				}
			})
		}
	})
}

export { usePreview, getStoryblokUrl }
